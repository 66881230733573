
@use "igniteui-angular/theming" as *;
// Uncomment the following lines if you want to add a custom palette:
// $primary: #731963 !default;
// $secondary: #ce5712 !default;
// $app-palette: palette($primary, $secondary, $surface);

/* autoprefixer grid: on */

@include core();
@include typography($font-family: $material-typeface, $type-scale: $material-type-scale);
@include theme($default-palette);
// CSS Reset, comment out if not required or using a different module
@import 'minireset.css/minireset';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    font-family: 'Roboto', sans-serif;
    background: #f4f7fa;
}

.card {
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    background-color: #fff;
    margin: 1.25rem 0 1.25rem 0;
    box-shadow: 0 .25rem .5rem #e6edef;
    color: #495057;

    .card-head {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid #edf1f7;
        font-size: 1rem;
        font-weight: 600;
    }

    .card-body {
        padding: 1rem 1.5rem;
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}